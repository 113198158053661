















































































import { CalendarEvent } from "vuetify";
import { computed, defineComponent, inject, onMounted, PropType, reactive, ref } from "@vue/composition-api";
import { getAllTeamSessionsForUser } from "@/api/teamSessionService";
import { userModule } from "@/store/modules/user";
import Learnlink from "@learnlink/interfaces";

export default defineComponent({
  name: "Calendar",
  props: {
    lessons: {
      type: Array as PropType<Learnlink.Lesson.FullViewApp[]>,
      default: () => [],
    },
    teams: {
      type: Array as PropType<Learnlink.Team.View.FullMetadata[]>,
      default: () => [],
    },
  },
  setup(props) {
    const vm = reactive({
      dates: [] as Learnlink.TeamSession.default[],
      focus: "",
      selectedOpen: false,
      currentLesson: {} as Learnlink.Lesson.FullViewApp,
      showLessonCardDialog: false,
      type: "month",
      weekdays: [1, 2, 3, 4, 5, 6, 0],
    });
    onMounted(async() => {
      vm.dates = await getAllTeamSessionsForUser(userModule.state.userId);
    });
    const colors = ["red darken-1", "red darken-4", "red accent-4", "pink darken-1", "pink darken-4", "pink accent-4", "purple darken-1", "purple darken-4", "purple accent-4", "blue darken-1", "blue darken-4", "blue accent-4", "cyan darken-1", "cyan darken-4", "cyan accent-4", "green darken-1", "green darken-4", "green accent-4", "lime darken-1", "lime darken-4", "amber darken-1", "amber darken-4", "amber accent-4", "brown darken-1", "green accent-2", "cyan accent-3", "light-blue accent-2", "deep-purple accent-2", "indigo accent-2", "red accent-2", "purple accent-2"];
    const calendar = ref();
    const calenderLessons = computed(() => {
      const teamEvents: CalendarEvent[] = [];
      vm.dates.map((date) => {
        teamEvents.push({
          name: props.teams.filter(team => team.ID === date.teamID)[0]?.title,
          start: date.startTime * 1000,
          end: date.endTime * 1000,
          timed: true,
          color: date.teamID[1] ? colors[date.teamID[1].charCodeAt(0) % colors.length] : "blue",
          ID: date.ID,
        } as CalendarEvent);
        return date;
      });
      return props.lessons.map(lesson => {
        return {
          name: getLessonName(lesson),
          start: lesson.startTime * 1000,
          end: lesson.endTime * 1000,
          timed: true,
          color: lesson.customerUID[1] ? colors[lesson.customerUID[1].charCodeAt(0) % colors.length] : "blue",
          ID: lesson.ID,
        } as CalendarEvent;
      }).concat(teamEvents);
    });

    function getLessonName(lesson: Learnlink.Lesson.FullViewApp): string {
      if (userModule.state.user.role === "seller") {
        return lesson.project?.studentName || `${lesson.customer?.profile?.firstName || "Slettet"} ${lesson.customer?.profile?.lastName || "Bruker"}`;
      }
      else if (lesson.seller) {
        return lesson.seller?.profile.firstName;
      }
      return "";
    }

    const showLessonCard = inject<(lesson: Learnlink.Lesson.FullViewApp) => void>("showLessonCard");

    function showEvent(event: CalendarEvent) {
      if (props.lessons.filter(lesson => lesson.ID === event.event.ID)[0]) {
        if (showLessonCard) showLessonCard(props.lessons.filter(lesson => lesson.ID === event.event.ID)[0]);
      }
    }

    function viewDay({ date }: Record<"date", string>) {
      vm.focus = date;
      vm.type = "day";
    }

    function prev() {
      calendar.value.prev();
    }

    function next() {
      calendar.value.next();
    }

    return {
      calenderLessons,
      calendar,
      next,
      prev,
      showEvent,
      viewDay,
      vm,
    };
  },
});
